import React from 'react'
import './style.css'
import img from '../../../assets/banner section images/about us image.png'

const index = () => {
  return (
    <section className='a-banner-container'> 
        <div className='a-left-part' data-aos="fade-up">
          <h2>Your Story, <span>Perfectly Edited</span> </h2>
          <p>Our Passion and Expertise
              Dedicated to Transforming Your Ideas into Stunning Visual Narratives
              Where Creativity Meets Precision in Every Frame
              Your Vision, Our Craftsmanship - Together We Create Magic.
          </p>
        </div>

        <div className='a-right-part' data-aos="fade-down"> 
           <img src={img} alt='img'/>
        </div>
    </section>
  )
}

export default index