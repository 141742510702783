import React from 'react'
import { chooseRight } from '../../../data/HomeData'
import img from '../../../assets/chooseMain.jpg'
import { Divider } from '@mui/material'
import './style.css'

const index = () => {
  return (
    <div className='choose-us-container'>

      <div className='h-c-content-wrap'>

        <h6 className='choose-title'>Why Sponsifyr</h6>
        <h3>We are here to boost your presence</h3>

        <div className='choose-main'>
            <section className='choose-left' data-aos="zoom-in">
               <div>
                  <div className='h-choose-img-container' >
                      <img className='choose-img' src={img} alt='img' />
                  </div>
               <div className='choose-left-bottom' >
                  <div>
                     <h3>2+</h3>
                     <p className='year-para'>Years of experience</p>
                  </div>
                     <Divider orientation="vertical" flexItem sx={{ borderColor: '#fff', borderRightWidth: 2 }} />
                  <div>
                     <h3>50+</h3>
                     <p className='year-para'> Clients Happy </p>
                  </div>
                    <Divider orientation="vertical" flexItem sx={{ borderColor: '#fff', borderRightWidth: 2 }} />
                  <div>
                     <h3>5k+</h3>
                     <p className='year-para'>Videos produced</p>
                  </div>
               </div>
               </div>
            </section>

            <section className='choose-right' data-aos="zoom-in">
               {chooseRight.map((val)=><RightChild key={val.id} {...val} />)} 
            </section>
        </div>
      </div>


    </div>
  )
}

export default index

let RightChild = ({icon ,title , content ,id})=>{
   return(

            <div className='RightChild' key={id}> 

                <img src={icon} className='h-choose-icon' alt='img'/>
             
                <div className='RightChild-inside'>
                    <h5>{title}</h5>
                    <p>{content} </p>
                </div>
            </div>
         )
}