import React from 'react'
import './style.css'
import Banner from '../../components/Services/banner'
import Solution from '../../components/Services/OurSolution'
import CardSection from '../../components/Services/CardSection'


const index = () => {
  return (
    <div className='service-wrapper'>
      <Banner/>
      <Solution/>
      <CardSection/>
      
    </div>
  )
}

export default index