import React from 'react'
import { CustomButton } from './style'

const index = ({children , icon , padding , margin , maxWidth , type}) => {



  return (
    <CustomButton
     type={type ? 'type' : ''}
      padding={padding}
      margin={margin}
      maxWidth={maxWidth}
      variant="contained"
      endIcon={icon ? icon : null}>
        {children}
     </CustomButton>  
  )
}

export default index