import { Button, styled } from "@mui/material";

 export const CustomButton = styled(Button)(({ theme , margin , padding ,maxWidth }) => ({
  backgroundColor: '#5c14dd',
  padding:padding ? padding:null,
  margin:margin ? margin:null,
  maxWidth: maxWidth ? maxWidth :null,
  color: '#ffffff', 
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#4159e0', 
  },
}));