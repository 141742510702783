import { Button } from '@mui/material';
import React from 'react'
import { FaRegCirclePlay } from "react-icons/fa6";

const ReelsCard = ({img , link , title }) => {
  return (
     <div className='p-cards-container' data-aos="flip-left">
       <div className='p-card-img-container'>
         <img src={img} alt='img' />
       </div>

       <div className='p-description' >
          <a href={link} target='blank'>
             <Button sx={{color:'white', fontSize:'50px' ,    transition: 'all .5s ease-in-out', borderRadius:'50%',backgroundColor:'transparent' , padding:'0px' , minWidth: '21px' }} className="play-button" >
             <FaRegCirclePlay className='Play-icon'/>
           </Button>
          </a>
           <h2>{title}</h2>
         </div>
     </div>
  )
}

export default ReelsCard



