import React from 'react'
import PrimaryBtn from '../../common/primaryBtn'
import './style.css'
import { Link } from 'react-router-dom'

const index = () => {
  return (
    <div className='h-footer' data-aos="zoom-in">
        <h2>No Editing - No Growth</h2>
        <h1>Upgrade your visual content today</h1>
        <p>Don’t settle for average visual content. Get in touch with us today to discover how our innovative video editing services can elevate your brand.</p>
         <Link to='/ContactUs'>
            <PrimaryBtn className='free-btn' margin ='35px 0px 0px 0px' padding ='5px 25px'>
             Free Consultation
         </PrimaryBtn>
         </Link>
    </div>
  )
}

export default index