import React from 'react'
import bannerImg from '../../../assets/banner section images/Service page images.png'
import './style.css'

const index = () => {
  return (
    <div className='s-banner-container'>
        <div className='s-banner-left'  data-aos="fade-up">
            <h1>Where Your Content Meets Expert Editing</h1>
            <p>
                As a growing video editing agency, we specialize in editing audience based, that build a strong brand's visibility and drives engagement.
            </p>
        </div>

        <div className='s-banner-right' data-aos="fade-down">
             <img src={bannerImg} alt='img'/>
        </div>

    </div>
  )
}

export default index