import React, { useEffect, useRef } from 'react';
import './style.css';
import img from '../../../assets/image.jpg';
import icon1 from '../../../assets/transform.svg';
import icon2 from '../../../assets/elevate.svg';
import icon3 from '../../../assets/deliver.svg';
import { Box } from '@mui/material';

const Index = () => {
  return (
    <div className='home-about-us'>
      <div className='home-content-wrapper'>
        <section className='top-section'>
          <div className='about-us-img-container' data-aos="fade-right">
            <img src={img} alt='img' />
          </div>

          <div className='top-section-right' data-aos="fade-left">
            <h6>About us</h6>
            <h3>Elevating your visual content to new heights</h3>

            <ProgressBar label='Audience Based Video Editing' width={99} />
            <ProgressBar label='Timely Delivery' width={85} />
            <ProgressBar label='Quick changes' width={90} />
            <ProgressBar label='Creative Style' width={96} />
          </div>
        </section>

        <section className='bottom-section'>
          <div className='bottom-section-left' data-aos="fade-right">
            <h2>Why Video Editing</h2>
            <div className='vision-para'>
              Big brands look for influencers that not only produce great content but also know how to present it through quality video editing. It’s crucial because it enhances engagement and aligns your content with the brand’s image.
            </div>
          </div>

          <div className='bottom-section-right' data-aos="fade-left">
            <h2>Our Mission</h2>
            <div>
              <div className='our-v-card'>
                <img src={icon1} alt='icon' />
                <p>Transforming your raw footage into polished</p>
              </div>

              <div className='our-v-card'>
                <img src={icon2} alt='icon' />
                <p>Editing to attract brands for promotion</p>
              </div>

              <div className='our-v-card'>
                <img src={icon3} alt='icon' />
                <p>Editing to attract customers</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Index;

const ProgressBar = ({ label, width }) => {
  const progressBarRef = useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target;
            target.style.width = `${width}%`;
            observer.unobserve(target); // Stop observing once the animation has started
          }
        });
      },
      { threshold: 0.5 } // 50% of the progress bar must be visible
    );

    if (progressBarRef.current) {
      observer.observe(progressBarRef.current);
    }

    return () => {
      if (progressBarRef.current) {
        observer.unobserve(progressBarRef.current);
      }
    };
  }, [width]);

  return (
    <div className='progress-section'>
      <label>{label}</label>
      <div className='progress'>
        <Box
          className='progress-value'
          ref={progressBarRef}
          sx={{ width: '0%' }} // Start with width 0%
        ></Box>
      </div>
    </div>
  );
};
