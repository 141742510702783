import React from 'react'
import Banner from '../../components/portfolio/banner'
import CaseStudy from '../../components/portfolio/caseStudy'
import './style.css'

const index = () => {
  return (
    <div className="portfolio-wrapper">
       <Banner/>
       <CaseStudy/>
    </div>
  )
}

export default index