import img1 from '../assets/service/card section images/Reels image.png'
import img2 from '../assets/service/card section images/shorts image.png'
import img3 from '../assets/service/card section images/Podcast image.png'
import img4 from '../assets/service/card section images/Youtube Image.png'
import img5 from '../assets/service/card section images/Service page-Advertisement_image.png'


export let ServiceCardData = [
    {
        title:'reels',
        content:'Our Reels Project aims to captivate and engage audiences through compelling short-form video content, driving brand awareness and enhancing digital presence.',
        img:img1
    },
    {
        title:'Shorts',
        content:`Whether you're looking to enhance your brand's presence, connect with your audience, or deliver your message in a fresh and dynamic way, our Shorts Video Project services are designed to meet your needs.`,
        img:img2
    },

    {
        title:'Podcast',
        content:'Our podcast video aims that combining the power of audio and visual media can enhance engagement and deliver valuable content in a dynamic way.',
        img:img3
    },

    {
        title:'YouTube',
        content:`From eye-catching visuals to compelling storytelling, we ensure your message resonates and captivates your audience.`,
        img:img4
    },
    {
        title:'Advertisements',
        content:`For Ads, we ensure your visuals are optimized to grab attention instantly and maintain viewer interest. Our editing enhances clarity and flow, turning your ads into high-converting content.`,
        img:img5
    },

]