import React from 'react'
import './style.css'
import { OurTeamData } from '../../../data/aboutData' 

const index = () => {
  return (
    <div className='a-footer-container'>
        <h1>Meet Our Team</h1>
      <div className='a-team-card-container'>
         {OurTeamData.map((val , index) => <Child key={index} {...val} />)}
      </div>
    </div>
  )
}

export default index



let Child = ({name , Designation , img})=>{


  return(
    <div className='a-team-card' data-aos="zoom-in">
       <div className='a-team-img-container'>
         <img src={img} alt='img'/>
       </div>
       <h3>{name} </h3>
       <p>{Designation} </p>
    </div>
  )
}