import React from 'react'
import './style.css'
import Banner from '../../components/about/banner'
import AboutBody from '../../components/about/body'
import AboutFooter from '../../components/about/AboutFoot'
import Bottom from '../../components/about/bottom'

const index = () => {
  return (
    <div className='About-wrapper'>
      <Banner/>
      <AboutBody/>
      <AboutFooter/>
      <Bottom/>
    </div>
  )
}

export default index