import React from 'react'
import Home from '../../components/home/banner'
import './style.css'
import HAboutUs from '../../components/home/HAbout'
import Choose from '../../components/home/chooseUs'
import Service from '../../components/home/services'
import Testimonial from '../../components/home/testimonial'
import Footer from '../../components/home/footer'

const index = () => {
  return (
    <div className='home-wrapper'>
        <Home/>
        <HAboutUs/>
        <Choose/>
        <Service/>
        <Testimonial/>
        <Footer/>
    </div>
  )
}

export default index