import { Grid, TextField } from '@mui/material'
import React from 'react'

const TextFiled = ({half , label , xs ,sm ,lg , md ,xl }) => {


  return (
    <Grid item  xs={xs} sm={sm} lg={lg} md={md} xl={xl} >
          <TextField label={label} 
           fullWidth
           required = {true}
           InputLabelProps={{
                required: true,
                classes: {
                asterisk: 'custom-asterisk',
                },
            }}
            />
    </Grid>
  )
}

export default TextFiled