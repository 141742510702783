import React from 'react'
import './style.css'
import Contact from '../../components/contact/'

const index = () => {
  return (
    <div className='contact-wrapper'>
      <Contact/>
    </div>
  )
}

export default index