import React from 'react'
import './style.css'
import { ReelData } from '../../../data/portfolio'
import ReelsCard from './caseCards/reelscard'
import { youTubeData } from '../../../data/portfolio'
import { podCastData } from '../../../data/portfolio'
import Sponsifyr from './caseCards/sponsifyr'
import { IoIosArrowForward } from "react-icons/io";
import { SponsifyrData } from '../../../data/portfolio'
import { Button } from '@mui/material'
import PrimaryBtn from '../../common/primaryBtn'

const index = () => {
  return (
    <div className='p-inside-wrapper'>
        <h2 className='p-main-title'>Our case Study</h2>
        <section className='p-reels-section'>
             <h2 className='p-card-title'>Reels & Shorts</h2>
             <div className='p-card-container'>
                 {ReelData.map((val , index)=> <ReelsCard key={index} {...val} />)}
             </div>
        </section>

        <section className='p-youTube-section'>
             <h2 className='p-card-title'>YouTube</h2>
             <div className='p-card-container'>
                 {youTubeData.map((val , index)=> <ReelsCard key={index} {...val} />)}
             </div>
        </section>

        <section className='p-podCast-section'>
             <h2 className='p-card-title'>Podcast</h2>
             <div className='p-card-container'>
                 {podCastData.map((val , index)=> <ReelsCard key={index} {...val} />)}
             </div>
        </section>

        <section className='p-podCast-section'>
             <h2 className='p-card-title'>Sponsifyr International</h2>
             <div className='p-card-container'>
                 {SponsifyrData.map((val , index)=> <Sponsifyr key={index} {...val} />)}
             </div>
        </section>

       <a href='https://drive.google.com/drive/folders/1JuFEblDTa092snxn89biURrsGc7MzsjT' target='_blank' className='ExploreBtn' >
           <PrimaryBtn icon = {<IoIosArrowForward/>} >
             Explore More
        </PrimaryBtn>
       </a>

    </div>
  )
}

export default index