import React from 'react'
import { ServiceData } from '../../../data/HomeData'
import './style.css'
import PrimaryBtn from '../../common/primaryBtn'
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

const index = () => {
  return (
    <div className='H-service-container'>
        {/* <div className='h-service-content-wrapper'> */}
            <h6> Our Services</h6>
              <h2>Innovative and High- Quality Video editing service</h2>
                <div className='service-img-container'>
                    {ServiceData.map((val , index)=>{
                        return(
                            <ServiceImgChild key={index + 1} image={val}/>
                        )
                    })}
                </div>

                <div className='h-read-more'>
                    <Link to='/Portfolio'>
                        <PrimaryBtn padding='4px 20px'  icon={<MdKeyboardArrowRight/>} >
                        View all
                        </PrimaryBtn>
                    </Link>
                </div>
        {/* </div> */}
    </div>
  )
}


export default index


let ServiceImgChild = ({image})=>{
   return(
    <div className='service-img-child' data-aos="zoom-out">
        <img src={image} alt='img'/>
    </div>
   )
}