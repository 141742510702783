import React from 'react'
import PrimaryBtn from '../../common/primaryBtn'
import { IoPlayCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';


const Child = ({title , content ,img ,index ,dataRight ,dataLeft}) => {


  let Val =  (index + 1)  % 2 === 0;


  return (
    <div className={`s-child-container `}>
        <div className={`s-child-container-inside ${Val ? 'to-reverse':''}`}>
           <section className='s-child-left' data-aos={dataRight}>
             <h2>{title} </h2>
             <p>{content} </p>
             <Link to='/Portfolio'>
               <PrimaryBtn icon={<IoPlayCircleOutline/>} >
               See Our Works
             </PrimaryBtn>
              </Link>
          </section>
        
        <section className='s-child-right' data-aos={dataLeft}>
            <img src={img} alt='img'/>
        </section>
        </div>
    </div>
  )
}

export default Child