import React from 'react'
import './style.css'

const index = () => {
  return (
    <div className='a-bottom' >
        <div className='a-bottom-inside' data-aos="zoom-out">
            <h3>Let’s Create Something Incredible</h3>
        <p>
           Whether you're looking to produce a compelling ad, a stunning music video, or a powerful documentary,<span className='a-bottom-sponsifyr'> Sponsifyr </span>
           is here to make it happen. We’re ready to take your vision and turn it into a visual masterpiece.
        </p>
        <h2>Client Retention Rate : 91%</h2>
        </div>
    </div>
  )
}

export default index