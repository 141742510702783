import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/Sponsifyr_logo_20240820_192452_0000-removebg-preview 1.svg'
import { TbMenu2 } from "react-icons/tb";
import { CgClose } from "react-icons/cg";
import { Link, useLocation } from 'react-router-dom'
import './style.css'

const Index = () => {

  let [Toggle , SetToggle] = useState(false)
  let [ToShowIcon , SetToShowIcon] = useState(false)
  let Location = useLocation()
  let [Initial , SetInitial] = useState(0)
  let [InnerWidth , SetInnerWidth] = useState(window.innerWidth)
  let [ToPopUp , SetToPopUp] = useState(false)
  let element = useRef(null)


   window.addEventListener('resize' , ()=>{
      SetInnerWidth(window.innerWidth)
   })

   useEffect(()=>{
      window.addEventListener('scroll' , ()=>{
        SetInitial(Math.floor(window.scrollY))
        if(Initial < Math.floor(window.scrollY)){
           SetToPopUp(true)
        }else{
            SetToPopUp(false)
        }
   })
  },[Initial])

   window.addEventListener('scroll' , ()=>{

      if( Math.floor(window.scrollY) > 200){
         SetToShowIcon(true)
      }else{
        SetToShowIcon(false)
      }
  })
  
  useEffect(()=>{

    if(InnerWidth > 768)return

     if(Toggle){
       document.body.style.overflowY = 'hidden';
       document.documentElement.style.overflowY = 'hidden';

    }else{
      document.body.style.overflowY = 'auto';
       document.documentElement.style.overflowY = 'auto';
    }
      
  },[Toggle])



  


  return (
   <div ref={element} className={` nav-wrapper ${ToPopUp ? 'nav-shadow':''}`}>
       <nav >
       <Link to='/'>
         <img src={Logo}  alt='logo'/>
        </Link>
          
          <div className='nav-btn' onClick={()=>SetToggle(!Toggle)} >
             { !Toggle ? <TbMenu2/> : <CgClose/> }
          </div>

       <ul className={`Navbar-list ${Toggle ? "popin ":''}`}>

      
            <NavItem to='/' SetToggle={SetToggle} Toggle = {Toggle} Item = 'Home' Path={Location.pathname} />

            <NavItem to='/service' SetToggle={SetToggle} Toggle = {Toggle} Item = 'Service' Path={Location.pathname} />

            <NavItem to='/portfolio' SetToggle={SetToggle} Toggle = {Toggle} Item = 'Portfolio' Path={Location.pathname} />

            <NavItem to='/aboutUs' SetToggle={SetToggle} Toggle = {Toggle} Item = 'About us' Path={Location.pathname} />

            <NavItem to='/contactUs' SetToggle={SetToggle} Toggle = {Toggle} Item = 'Contact us' Path={Location.pathname} />
          
       </ul>
    </nav>
   </div>
  )
}

export default Index



let NavItem = ({to, Path , SetToggle , Toggle ,Item})=>{


  return(
      <li>
         <Link className={` Navbar-list-link ${Path == to ? 'Navbar-list-active':''}` } to={to} onClick={()=>SetToggle(!Toggle)} >{Item}</Link>
      </li>
  )
}