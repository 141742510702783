import React from 'react'
import { ServiceCardData } from '../../../data/serviceData'
import Child from './Child'
import './style.css'

const index = () => {
  return (
    <div className='solution-section'>
        <h1>We specialize in editing</h1>
        <div className='card-container'>
           {ServiceCardData.map((val , index)=>  <Child key={index} {...val}  index = {index} dataRight="fade-right" dataLeft="fade-left" />)}
        </div>
    </div>
  )
}

export default index