import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'


const index = () => {
  return (
   <>
    <div className='footer-wrapper'>
      <div className='h-footer-container'>
        <div className='h-footer-sponsifyr'>
            <h2>Sponsifyr</h2>
            <p>
               Experience the impact of captivating video storytelling with us.
               For any inquiries, collaboration ideas, or simply to say hello, do not hesitate to get in touch.
             </p>
        </div>
       <div className='child-container'>
          <div className='h-footer-child'>
            <h3>Company</h3>
             <ul>
                 <li>
                     <Link to='/service'>Service</Link>
                 </li>
                 <li>
                     <Link to='/Portfolio'>Portfolio</Link>
                 </li>
                 <li>
                     <Link to='/AboutUs'>About Us</Link>
                 </li>
                 <li>
                     <Link to='/ContactUs'>Contact Us</Link>
                 </li>
             </ul>
        </div>
        <div className='h-footer-child'>
            <h3>Services</h3>
              <ul>
                 <li>
                     <Link to='/Portfolio'>Video Editing</Link>
                 </li>
                 <li>
                     <Link  to='/Portfolio'>Digital Marketing</Link>
                 </li>
              </ul>
        </div>
        <div className='h-footer-child'>
            <h3>Contact</h3>
             <ul>
                <li>
                    <a href="mailto:contact@sponsifyr.com">contact@sponsifyr.com</a>
                </li>
                <li>
                    <a href="tel:7631573779">+91-76315-73779</a>
                </li>
             </ul>
         </div>
       </div>
       </div>

       <hr className='footer-hr'/>
       
       <div className='footer-bottom'>
          <p>&#169;2024 Sponsifyr. All rights reserved</p>
          <p > Powered by <a className='progg' target='blank' href='https://progg.in/'> PROGG</a></p>
       </div>
    </div>
   </>
  )
}

export default index