import img1 from '../assets/About Us/Team 1st image.png'
import img2 from '../assets/About Us/Team 2nd image.png'
import img3 from '../assets/About Us/Team 3rd image.png'
import img4 from '../assets/About Us/Team 4th image.png'
import img5 from '../assets/About Us/Team 5th image.png'



export let OurTeamData = [
    {
      img:img1,
      name:'Sara',
      Designation:'Creative Director',
    },
    {
      img:img2,
      name:'Maya',
      Designation:'Lead Video Editor',
    },
    {
      img:img3,
      name:'John',
      Designation:'Motion Graphic Designer',
    },
    {
      img:img4,
      name:'Ben',
      Designation:'Sound Designer ',
    },
    {
      img:img5,
      name:'Ester',
      Designation:'Project Manager',
    },
]