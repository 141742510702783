import React from 'react'
import img from '../../../assets/banner section images/portfolio image.png'
import './style.css'

const index = () => {
  return (
    <section className='portfolio-banner'>
        
       <div className='p-left'  data-aos="fade-up">
        <h2>Where <span>Creativity</span> Meets Precision</h2>
        <p> Bringing Your Ideas to Life with Expert Video Editing and Motion Graphics.</p>
       </div>

       <div className='p-right'  data-aos="fade-down">
            <img src={img} alt='img' />
       </div>
    </section>
  )
}

export default index