import { Testimonial } from '../../../data/HomeData';
import { Swiper, SwiperSlide } from 'swiper/react';
import test1 from '../../../assets/About Us/Team 1st image.png'
import test2 from '../../../assets/About Us/Team 2nd image.png'
import test3 from '../../../assets/About Us/Team 3rd image.png'
import test4 from '../../../assets/About Us/Team 4th image.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.css';

import { Keyboard, Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function App() {
  return (
    <div className='testimonial-in-wrap'>
      <div className='testimonial'>
      <h1 className='Testimonial-head'>Testimonial</h1>
      <p className='Testimonial-para'>Our Video Editing Services Exceed Expectations</p>
      <Swiper
        slidesPerView={2}
        loop={true}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}

        breakpoints={{
  // when window width is >= 480px
  280: {
    slidesPerView: 1,
    spaceBetween: 10,
  },

  // when window width is >= 1168px
  1168: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
}}


        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[ Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className='Slide-Card'>
            <div className='slide-card-img-container'>
               <img src={test1} alt='img' />   
            </div>

            <div className='slider-bottom'>
                 <h5>Emily</h5>
                    <h3>Content Creator</h3>
                    
                    <p>“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that
                        perfectly matched our brand identity. We highly recommend their services. 
                    </p>
            </div>
         </SwiperSlide>
        <SwiperSlide className='Slide-Card'>
            <div className='slide-card-img-container' >
               <img src={test2} alt='img' />   
            </div>

            <div className='slider-bottom'>
                 <h5>John</h5>
                    <h3>Sales & Marketing</h3>
                    
                    <p>“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that
                        perfectly matched our brand identity. We highly recommend their services.”
                    </p>
            </div >
         </SwiperSlide>
        <SwiperSlide className='Slide-Card'>
            <div className='slide-card-img-container'>
               <img src={test3} alt='img' />   
            </div>

            <div className='slider-bottom'>
                 <h5>Richard</h5>
                    <h3>Designer</h3>
                    
                    <p>“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that
                        perfectly matched our brand identity. We highly recommend their services.”
                    </p>
            </div>
         </SwiperSlide>
        <SwiperSlide className='Slide-Card ' >
            <div className='slide-card-img-container'>
               <img src={test4} alt='img' />   
            </div>

            <div className='slider-bottom'>
                 <h5>Peter</h5>
                    <h3>Developer</h3>
                    
                    <p>“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that
                        perfectly matched our brand identity. We highly recommend their services.”
                    </p>
            </div>
         </SwiperSlide>

      </Swiper>
    </div>
    </div>
  );
}

