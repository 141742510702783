import React from 'react'
import './style.css'
import { MdLocationPin } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { Button, Grid } from '@mui/material';
import { IoMdMail } from "react-icons/io";

import PrimaryBtn from '../../components/common/primaryBtn'
import { IoLogoWhatsapp } from "react-icons/io";

import { IoIosMailUnread } from "react-icons/io";
import TextFiled from './Input';
import { Link } from 'react-router-dom';

const Index = () => {


    let HandleSubmit = (e)=>{
       e.preventDefault()
    }

  return (
    <div className='contact-container'>
        <section className='contact-left' data-aos="fade-right">
            <div className='contact-top'>
                  <h3>Contact Information</h3>
                  <p>Say something to start a live chat!</p>
            </div>

            <div className='contact-bottom'>
                 <div className='contact-bottom-child'>
                    <IoMdMail className='contact-icon'/>
                    <Link  href="mailto:info@sponsifyr.com">info@sponsifyr.com</Link>
                 </div>

                 <div className='contact-bottom-child'>
                    <IoIosMailUnread className='contact-icon'/>
                    <Link href="mailto:contact@sponsifyr.com" >contact@sponsifyr.com</Link>
                 </div>

                 <div className='contact-bottom-child'>
                    <IoLogoWhatsapp className='contact-icon'/>
                    <Link  href="https://wa.me/7631573779"  target='_blank'> +91-76315-73779</Link>
                 </div>

                 <div className='contact-bottom-child'>
                    <FaPhoneVolume className='contact-icon'/>
                    <Link  href="tel:7631573779" target="_blank" > +91-76315-73779</Link>
                 </div>
            </div>

        </section>

            <section className='contact-right' data-aos="fade-left">
                
                <h1 className='c-form-title'> Reach Us:</h1>

                <form onSubmit={HandleSubmit} >
                    <Grid container spacing={5}className='contact-right'>
                        <TextFiled label='First Name' xs={12} sm={12} md={12} lg={6} xl={6} />
                        <TextFiled label='Last Name' xs={12} sm={12} md={12} lg={6} xl={6} />
                        <TextFiled  label='Email' xs={12} sm={12} md={12} lg={6} xl={6} />
                        <TextFiled label="Phone Number" xs={12} sm={12} md={12} lg={6} xl={6} />
                        <TextFiled label = 'Detail'  xs={12} sm={12} md={12} lg={12} xl={12}  />
                    </Grid>
                    <div  className="Contact-btn">
                      <PrimaryBtn type='submit' > Send a Message  </PrimaryBtn>
                  </div>
                </form>
                
            </section>

    </div>
  )
}

export default Index