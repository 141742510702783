import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md";
import './style.css'
import PrimaryBtn from '../../common/primaryBtn'
import { ClientData } from '../../../data/HomeData';
import BannerImg from '../../../assets/banner section images/Homepage image.png'
import Aos from 'aos';
import { Link } from 'react-router-dom';

const Index = () => {


  useEffect(() => {
   Aos.init({
    duration: 1200, // Duration of the animations in milliseconds
    });
  }, []);

  return (
    <>
    <div className='banner-container' >
        <div className='content-wrap' data-aos="fade-up">
            <div className='banner-container-right'>
              <h2>Welcome to <span className='bName'> Sponsifyr</span></h2>
              <h1>Transform Your Vision into Reality with Professional Video Editing.</h1>
              <h4>Expertly crafted videos that resonate with your audience.</h4>
              <Link to='/ContactUs'><PrimaryBtn variant='contained' icon={<MdKeyboardArrowRight/>}> Get a Free Quote</PrimaryBtn></Link>
          </div>

          <div className='banner-img-container' data-aos="fade-down">
              <img src={BannerImg}  alt='img' />
          </div>
        </div>
    </div>

       <div className='client-container'>
           <h2 className='trusted-by'>Trusted By</h2>
           <div className='Client-img-container' data-aos="zoom-out">
                {ClientData.map((val)=>(
                 
                   <a className='client-card' href={val.link} target='blank'>
                      <div className='client-img-container'>
                        <img src={val.img} alt='img' />
                      </div>
                        <p className='client-name'> {val.name} </p>
                   </a>
                 ))}
           </div>
       </div>

    </>
  )
}

export default Index