import './App.css';
import Home from './pages/home'
import { FaWhatsapp } from "react-icons/fa6";
import Navbar from './components/common/Navbar'
import { MdArrowUpward } from "react-icons/md";
import { Button } from '@mui/material';
import Service from './pages/service'
import Logo from './assets/Sponsifyr_logo_20240820_192452_0000-removebg-preview 1.svg'
import 'aos/dist/aos.css';
import Portfolio from './pages/portfolio'
import AboutUs from './pages/aboutUs'
import ContactUs from './pages/contactUs'
import Footer from './components/common/footer'
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Aos from 'aos';

function App() { 
  const { pathname } = useLocation();
  const [ToLogoAnimate , SetLogoAnimate] = useState(true)

   useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pathname]);




  window.onload = function() {
    setTimeout(() => {
        Aos.init();
    }, 500); 
};

  let [ToShowIcon , SetToShowIcon] = useState(false)


  let ToScrollUp = ()=>{
    window.scrollTo({top: 0,behavior: 'smooth' });
  }


  window.addEventListener('scroll' , ()=>{

      if( Math.floor(window.scrollY) > 200){
         SetToShowIcon(true)
      }else{
        SetToShowIcon(false)
      }
  })




  const handleWhatsAppClick = () => {
    const phoneNumber = '+91 76315 73779'; 
    const message = 'Hello! I would like to know more about your services.'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    window.open(whatsappUrl, '_blank');
    
  };

  useEffect(()=>{
    setTimeout(()=>{
      SetLogoAnimate(false)
    } ,5000)
  },[])

  if(ToLogoAnimate){
    return(
      <div className="splash-screen">
        <img src={Logo} alt="Company Logo" className="logo" />
     </div>
    )
  }
  

  return (
    <div className='Wrapper'>
        <Navbar/>
      <Routes>
        
        <Route path='/' element={<Home />}/>
        <Route path='/service' element={ <Service/>}/>
        <Route path='/Portfolio' element={ <Portfolio/>}/>
        <Route path='/AboutUs' element={ <AboutUs/>} />
        <Route path='/ContactUs' element={ <ContactUs/>} />
      </Routes>
        
        <Button className='whtAnimate' onClick={handleWhatsAppClick} sx={{borderRadius:'32px' , position:'fixed', bottom:'130px' , right:'25px' ,zIndex:'3' ,textTransform: 'none' , backgroundColor:'#25D366'}} startIcon={<FaWhatsapp />} variant='contained'>
              Chat with us
        </Button>
             
             <button onClick={ToScrollUp} className={`ScrollBtn ${ToShowIcon ? 'ScrollBtnToHide':''} `}>
                <MdArrowUpward className='moveIcon'/>
             </button>

        <Footer/>
    </div>
  );
}

export default App;
