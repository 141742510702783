import React from 'react'
import './style.css'
import img from '../../../assets/About Us/Body section image.png'

const index = () => {
  return (
    <div className='a-body-container'>
       <section className='a-body-top' data-aos="fade-up"
     data-aos-anchor-placement="top-center">
          <h1>Who We Are</h1>
          <p>Founded by a 3+ Years experienced video editing professional along with a Marketing student, Sponsifyr has quickly grown into a leading video editing studio dedicated to helping brands and creators to tell their stories. Our team of editors, animators, and sound designers work closely with clients to ensure every project not only meets but exceeds expectations at the committed time..</p>
       </section>

        <section className='a-body-bottom'>
           <div className='a-body-img-container' data-aos="fade-right">
             <img src={img} alt='img' />
           </div>

           <div data-aos="fade-left">
              <h2>What makes us unique</h2>
              <p>What makes Sponsifyr unique is our unwavering commitment to storytelling through innovative and personalized video editing. We go beyond simply piecing together footage; we delve into the heart of your narrative, understanding your vision and crafting it into a visually stunning and emotionally resonant experience. Our blend of creative artistry, cutting-edge techniques, and a collaborative approach ensures that every project we deliver not only meets but exceeds expectations, making your story not just seen, but truly felt.</p>
           </div>

        </section>

    </div>
  )
}

export default index