
import reelsImg1 from '../assets/portfolio/1.Reels & shorts/1st image.png'
import reelsImg2 from '../assets/portfolio/1.Reels & shorts/2nd image.png'
import reelsImg3 from '../assets/portfolio/1.Reels & shorts/3rd image.png'
import reelsImg4 from '../assets/portfolio/1.Reels & shorts/4th image.png'
import reelsImg5 from '../assets/portfolio/1.Reels & shorts/5th image.png'
import reelsImg6 from '../assets/portfolio/1.Reels & shorts/6th image.png'
import reelsImg7 from '../assets/portfolio/1.Reels & shorts/7th image.png'
import reelsImg8 from '../assets/portfolio/1.Reels & shorts/8th image.png'

import Spons1 from '../assets/portfolio/SponsifyrInter/img1.jpg'
import Spons2 from '../assets/portfolio/SponsifyrInter/img2.jpg'
import Spons3 from '../assets/portfolio/SponsifyrInter/img3.jpg'

import YouTube1 from '../assets/portfolio/2.YouTube/1st image.png'
import YouTube2 from '../assets/portfolio/2.YouTube/2nd image.png'
import YouTube3 from '../assets/portfolio/2.YouTube/3rd image.png'
import YouTube4 from '../assets/portfolio/2.YouTube/4th image.png'
import YouTube5 from '../assets/portfolio/2.YouTube/5th image.png'
import YouTube6 from '../assets/portfolio/2.YouTube/6th image.png'

import podCast1 from '../assets/portfolio/3.Podcast/1st image.png'
import podCast2 from '../assets/portfolio/3.Podcast/2nd image.png'
import podCast3 from '../assets/portfolio/3.Podcast/3rd image.png'


export let ReelData = [
    {
        img:reelsImg1,
        title:'Advocate',
        link:'https://drive.google.com/file/d/1blarmfzZq2JjDVlWyLAw9eZfxZOlo2Eq/view?usp=sharing'
    },
    {
        img:reelsImg2,
         title:'Affiliate',
        link:'https://drive.google.com/file/d/1aP3Re1BFgXcAi8ZIA9B-i0Hvi6v2H3Uk/view?usp=sharing'
    },
    {
        img:reelsImg3,
         title:'AI',
        link:'https://drive.google.com/file/d/1EP6P-1HfU0U3wDw8LyG0vyJi4FhDb676/view?usp=sharing'
    },
    {
        img:reelsImg4,
         title:'Finance',
        link:'https://drive.google.com/file/d/1EGSpktHixC3YLYxQTe7G0K-_nIYDd-Ea/view?usp=sharing'
    },
    {
        img:reelsImg5,
         title:'Health',
        link:'https://drive.google.com/file/d/1pFuuqHcIh6Dfl1bb123vXTOugXDofVTw/view?usp=sharing'
    },
    {
        img:reelsImg6,
         title:'Advertisement',
        link:'https://drive.google.com/file/d/1G-iQaMmARwLI84panFzuWCHBZ5vR1O_j/view?usp=sharing'
    },
    {
        img:reelsImg7,
         title:'Product AD',
        link:'https://drive.google.com/file/d/1NeoKuyboJEbySNPM1_mPrDzp08piP9zI/view?usp=sharing'
    },
    {
        img:reelsImg8,
         title:'WARMHLEE AD',
        link:'https://drive.google.com/file/d/1hgcsbf2bvD1g-Zi8O2l3T4CLuPAjctY0/view?usp=sharing'
    },
]

export let youTubeData = [
    {
        img:YouTube1,
        title:'Modern India',
        link:'https://drive.google.com/file/d/1O7zvRhas0c6qivtN9O1wLkfsBeKHpQ4k/view?usp=sharing'
    },
    {
        img:YouTube2,
        title:'Finance',
        link:'https://drive.google.com/file/d/1eKF-5hDUnSSIExsMH8D5AgVLuBWoFOcR/view?usp=sharing'
    },
    {
        img:YouTube3,
        title:'Graphic Design',
        link:'https://drive.google.com/file/d/1OBJKM75c-DEFQdS10uk2eya8ZRgehVpO/view?usp=sharing'
    },
    {
        img:YouTube4,
        title:'Health',
        link:'https://drive.google.com/file/d/1mOxDUzAiR49bsWv0a7ZGvntlK_IZkKVT/view?usp=sharing'
    },
    {
        img:YouTube5,
        title:'Business',
        link:'https://drive.google.com/file/d/1z1SRdGPxildO-95o9wv4USzXdxW3qlAI/view?usp=sharing'
    },
    {
        img:YouTube6,
        title:'Trade',
        link:'https://drive.google.com/file/d/1cvdyCC10pkG9wnAVrLgPyRh6vC-ByBN9/view?usp=sharing'
    },
   
]

export let podCastData = [
    {
        img:podCast1,
        title:'Balancing College life',
        link:'https://drive.google.com/file/d/1FfYO2CfVy1CI6rvbetC45Ivtb8gQduaF/view?usp=sharing'
    },
    {
        img:podCast2,
        title:'Constitution Books',
        link:'https://drive.google.com/file/d/1Fj9Dlb8SahRdF8RwnxskNuCikcYgY0sT/view?usp=sharing'
    },
    {
        img:podCast3,
        title:'Indial cases',
        link:'https://drive.google.com/file/d/1Fas-uoWEjwLF6vod3E4Ro5J02koPjFSX/view?usp=sharing'
    },
]

export let SponsifyrData = [
    {
        img:Spons1,
        title:'Restaurant',
        link:'https://drive.google.com/file/d/14EXzhIK8EoEuupEHIGkyeOVjzmnnENKC/view?usp=sharing'
    },
    {
        img:Spons2,
        title:'Ai Personalize',
        link:'https://drive.google.com/file/d/1GWh6M7h7f9WCe4wV7R3vNn6WI4avwegV/view?usp=sharing'
    },
    {
        img:Spons3,
        title:'Instagram Growth',
        link:'https://drive.google.com/file/d/1Q44XClX9xkWfQczXQBPqmQ2KMHnEovFT/view?usp=sharing'
    },
]