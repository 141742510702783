import img1 from '../assets/sponsify clients/Client 4.jpg'
import img2 from '../assets/sponsify clients/client 5.jpg'
import img3 from '../assets/sponsify clients/client 6.jpg'
import img4 from '../assets/sponsify clients/client 9.jpg'
import img5 from '../assets/sponsify clients/client 7.jpg'
import img6 from '../assets/sponsify clients/client 8.jpg'
import img7 from '../assets/sponsify clients/Client 1.jpg'
import img8 from '../assets/sponsify clients/client 2.jpg'
import img9 from '../assets/sponsify clients/client 3.jpg'

import test1 from '../assets/About Us/Team 1st image.png'


import icon1 from '../assets/badge.svg'
import icon2 from '../assets/centralized.svg'
import icon3 from '../assets/courier 1.svg'
import icon4 from '../assets/podcaster 1.svg'

import ServiceImg1 from '../assets/serviceImg1.jpg'
import ServiceImg2 from '../assets/serviceImg2.jpg'
import ServiceImg3 from '../assets/serviceImg3.jpg'
import ServiceImg4 from '../assets/serviceImg4.jpg'


import { FaInstagram } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { SiYoutubeshorts } from "react-icons/si";
import { HiSpeakerphone } from "react-icons/hi";



export let ClientData = [
    {
     img: img1,
     name:'Puneet Nagpal',
     link:'https://www.instagram.com/cfppuneetnagpal/'
    },
    {
     img: img2,
     name:'Dr.Robin Sharma',
     link:'https://www.instagram.com/dr.sharmarobin/'
    },
    {
     img: img3,
     name:'Pawan Reley',
     link:'https://www.instagram.com/pawan_reley/'
    },
    {
     img: img4,
     name:'ayurmedixindia',
     link:'https://www.instagram.com/ayurmedixindia/'
    },
    {
     img: img5,
     name:'TIM',
     link:'https://www.instagram.com/timesinfluentialmedia/'
    },
    {
     img: img6,
     name:'Sampoorn Prakartik',
     link:'https://www.instagram.com/sampoornprakartik/'
    },
    {
     img: img7,
     name:'Vivek Ahuja',
     link:'https://www.instagram.com/dr.vivekahuja/'
    },
    {
     img: img8,
     name:'Prashana Kundli',
     link:'https://www.instagram.com/prashnakundli.official/'
    },
    {
     img: img9,
     name:'Gurbaj Singh',
     link:'https://www.instagram.com/nutritionguru99/'
    },

]

export let ServiceData = [ServiceImg1 ,ServiceImg2 ,ServiceImg3, ServiceImg4]

export let chooseRight = [
    {
        id:1,
        title:'Audience based editing',
        content:'We craft content that connects with your audience, bringing your vision to life.',
        icon:icon2
    },
    {
        id:2,
        title:'Promotion Assistance',
        content:'Your vision, our craft. We collaborate closely to ensure every frame and cut tells your story just the way you imagined.',
        icon:icon1
    },
    {
        id:3,
        title:'Timely Delivery',
        content:'We respect your deadlines and ensure timely delivery without compromising on quality.',
        icon:icon3
    },
    {
        id:4,
        title:'Podcast Guests',
        content:'We work closely with you to turn your vision into stunning visual stories, ensuring every project reflects your unique creativity.',
        icon:icon4
    },
]


export let SolutionData = [
    {
        id:1,
        icon:<FaInstagram/>,
        count:'1,200',
        account:'Account Optimized'
    },
    {
        id:2,
        icon:<BsYoutube/>,
        count:'100+',
        account:'Account grow'
    },
    {
        id:3,
        icon:<SiYoutubeshorts/>,
        count:'5+',
        account:'Account engaged'
    },
    {
        id:4,
        icon:<HiSpeakerphone/>,
        count:'100+',
        account:'Account engaged'
    },
]


export let Testimonial = [
    {
      img:test1,
      name:'Emily',
      title:'Content Creator',
      content:'“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that perfectly matched our brand identity. We highly recommend their services.”'
    },
    {
      img:test1,
      name:'Emily',
      title:'Content Creator',
      content:'“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that perfectly matched our brand identity. We highly recommend their services.”'
    },
    {
      img:test1,
      name:'Emily',
      title:'Content Creator',
      content:'“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that perfectly matched our brand identity. We highly recommend their services.”'
    },
    {
      img:test1,
      name:'Emily',
      title:'Content Creator',
      content:'“Sponsifyr’s team of expert video editors exceeded our expectations, delivering visually stunning content that perfectly matched our brand identity. We highly recommend their services.”'
    },

]