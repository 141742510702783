import React from 'react'
import './style.css'
import SolutionImg from '../../../assets/service/solution.jpg'
import { SolutionData } from '../../../data/HomeData'

const index = () => {
  return (
    <section className='solution-container'>
        <div className='solution-para'>
             <p>We are <span>Sponsifyr</span> – a video editing service that
              specializes in creating captivating video content. Let us help you bring your vision to life.
        </p>
        </div>

        <div className='solution-main'>
            <img className='solution-left' src={SolutionImg} data-aos="fade-right"/>

            <div className='solution-right' data-aos="fade-left">
                <h6>We’ve done</h6>
                <h2>Crafting compelling video content</h2>
                <p>Our team of experts is here to help elevate your brand with visually stunning and engaging video content.
                   Whether it’s a short or a podcast, we’ve got you covered.
                </p>
                <div className='solution-child-container'>
                    {SolutionData.map((val)=><Child key={val.id} {...val} /> )}
                     
                </div>
            </div>
        </div>

    </section>
  )
}

export default index


let Child = ({icon ,count ,account})=>{
    return(
        <div className='solution-child-inside' >
            <div className='solution-icon'>
               {icon}
            </div>
            <div className='child-content'>
                 <h1>{count} </h1>
                 <p>{account} </p>
            </div>
        </div>  
    )
}